<template>
    <div class="h-100 flex-display flex-column flex-center">
        <div>
            <img src="/img/logo_img.svg" alt="myonline therapy"/>
        </div>
        <h1 class="form-heading mt-5">Reset Password</h1>
        <p class="app-error mt-2">
            {{error}}
        </p>
        <div class="w-343 mt-3" id="form">
            <div class="form-group">
                <label class="form-label">Password</label>
                <input class="input-text" type="password" v-model="password" placeholder="Enter New Password">
                <div class="error-block mt-3 text-left">
                    <p v-for="rule in rules" class="mb-0" :class="rule.class">{{rule.message}}</p>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label">Confirm Password</label>
                <input class="input-text" type="password" v-model="confirmPassword" placeholder="Confirm Password">
            </div>
            <button class="btn" :disabled="disable" @click="ResetPassword">Reset Password</button>
            <div class="text-center mt-2">
                <router-link to="/login">Return To Sign in</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResetPassword",
        data(){
            return{
                password: '',
                confirmPassword:'',
                disable: true,
                error:'',
                token:'',
                email:'',
                rules: [
                    { message:"Minimum 8 characters", regex:/.{8,}/,class:'text-dark' },
                    { message:"At least 1 number", regex:/[0-9]+/,class:'text-dark' },
                    { message:"At least 1 uppercase ",  regex:/[A-Z]+/,class:'text-dark' },
                    { message:'At least 1 lowercase', regex:/[a-z]+/,class:'text-dark' },
                ],
                errors:[]
            }
        },
        created(){
             this.email = this.$route.query.email ? this.$route.query.email : '';
             this.token = this.$route.query.token ?  this.$route.query.token : '';
        },
        methods:{
            ResetPassword(){
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url+'password/reset', {
                    email: this.email,
                    token: this.token,
                    password: this.password,
                    confirm_password: this.confirmPassword
                }).then(response => {
                    loader.hide();
                    self.$toastr('success', response.data.message,'');
                    localStorage.setItem('password_expire_date',response.data.password_expire_date);
                    self.$router.push('/login');
                }).catch(function (error) {
                    loader.hide();
                    self.error = error.response.data.message;
                });
            },
            enableButton(){
                this.disable = !( this.password !=='' && this.confirmPassword !== '' && this.errors.length <=0 && this.email !== '' && this.token !== '' && (this.password === this.confirmPassword))
            }
        },
        watch:{
            email: function () {
                this.enableButton();
            },
            password: function() {
                this.errors =[];
                for (let condition of this.rules) {
                    if (this.password === '') {
                        condition.class = 'text-dark';
                    } else if (!condition.regex.test(this.password)) {
                        condition.class = 'text-danger';
                        this.errors.push(condition.message);
                    } else {
                        condition.class = 'text-success';
                    }
                }
                this.enableButton();
            },
            confirmPassword: function() {
                this.enableButton();
            }
        }
    }
</script>

<style scoped>

</style>